$gutter: 1rem; //for primeflex grid system

//@import "theme/blue/theme-light";
//@import "layout/light/styles";

@import "~primeng/resources/primeng.min.css";
@import "~primeflex/primeflex.scss";
@import "~primeicons/primeicons.css";
@import "~prismjs/themes/prism-coy.css";

.form-group
{
  margin-bottom: 1rem;
}

.product-card
{
  background-color: #fdf1fc;
}

.modal-footer-buttons
{
  button
  {
    float: left;

    &:last-of-type
    {
      float: right;
    }
  }
}

.w-full
{
    width: 100%;
}

.p-column-filter
{
  width: 100%;
}

nb-layout-header.fixed
{
  z-index: 1001 !important;
}

.p-button
{
  margin-right: .5rem;
}

.bg-danger,
.bg-danger .p-sidebar
{
  background-color: #f1556c;
  color: #ffffff;
  display: flex;
  align-items: center;

  p,
  button
  {
    color: #ffffff;
  }

  p-footer
  {
    display: flex;
    justify-content: space-evenly;
  }
}

.bg-button-double
{
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;

  button
  {
    display: inline-block;
  }
}
